<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <v-tabs
        v-model="currentTabIdx"
        background-color="transparent"
        class="elevation-0"
      >
        <v-tab
          v-for="type in types"
          :key="`rebate_tab_${type.value}`"
          @click="currentForm = { type: type.value }"
        >
          {{ type.text }}
        </v-tab>
      </v-tabs>

      <div class="px-1 py-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              fab
              small
              :ripple="false"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title
              v-if="!currentForm.id || (currentForm.id && currentForm.type)"
            >
              {{
                `${currentForm.id ? '': '新規 '}${{VendingRebate: '商品', SalesRebate: '売上', ReferringRebate: '紹介' }[currentForm.type]}バック要素${currentForm.id ? ' 編集' : ''}`
              }}
            </v-card-title>

            <v-card-text>
              <component
                :is="`${currentForm.type}Form`"
                v-if="currentForm.type"
                :key="`${currentForm.type}Form-${isFormOpen}`"
                v-model="currentForm"
                :vendibles="vendibles"
                :vendible-tags="vendibleTags"
                :referrals="referrals"
                :table-filters="tableFilters"
                @validate="isValidToSubmit = $event"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                :disabled="!isValidToSubmit"
                :loading="isSubmitting"
                @click="submit"
              >
                {{ currentForm.id ? '更新' : '作成' }}
              </v-btn>
              <v-btn
                :ripple="false"
                color="blue darken-1"
                text
                @click="cancelForm"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-tabs-items
        v-model="currentTabIdx"
        style="background: transparent"
        touchless
      >
        <v-tab-item
          v-for="(rebates, type) in rebatesByType"
          :key="`v-tab-item-${type}`"
        >
          <component
            :is="`${type}Datatable`"
            :key="`${type}Datatable-${rebates.length}`"
            :rebates="rebates"
          >
            <template #action="{ item }">
              <span class="d-flex align-center justify-end">
                <v-btn
                  :ripple="false"
                  icon
                  @click="
                    currentForm = { id: item.id, ...item.attributes, type: type };
                    isFormOpen = true"
                >
                  <v-icon small>
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>

                <yona-edit-dialog
                  title="削除の確認"
                  btn-color="error"
                  save-text="同意の上で削除"
                  @save="destroy(item.id, type)"
                >
                  <template #append-outer-display-name>
                    <v-btn
                      icon
                      :ripple="false"
                    >
                      <v-icon small>
                        {{ icons.mdiTrashCanOutline }}
                      </v-icon>
                    </v-btn>
                  </template>

                  <template #input>
                    <span>
                      削除を実行してもよろしいですか。
                    </span>
                  </template>
                </yona-edit-dialog>
              </span>
            </template>
          </component>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiTrashCanOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { findIndex, camelCase } from 'lodash'
import RebateApi from '@/api/admin/Rebate'
import VendingRebateApi from '@/api/admin/VendingRebate'
import SalesRebateApi from '@/api/admin/SalesRebate'
import ReferringRebateApi from '@/api/admin/ReferringRebate'
import VendibleApi from '@/api/waiter/Vendible'
import ReferralApi from '@/api/waiter/Referral'
import VendibleTagApi from '@/api/waiter/VendibleTag'
import TableFilterApi from '@/api/admin/TableFilter'
import useCompInit from '@/views/composable/useCompInit'
import VendingRebateForm from '@/views/components/rebate/form/VendingRebate.vue'
import SalesRebateForm from '@/views/components/rebate/form/SalesRebate.vue'
import ReferringRebateForm from '@/views/components/rebate/form/ReferringRebate.vue'
import VendingRebateDatatable from '@/views/components/rebate/datatable/VendingRebate.vue'
import SalesRebateDatatable from '@/views/components/rebate/datatable/SalesRebate.vue'
import ReferringRebateDatatable from '@/views/components/rebate/datatable/ReferringRebate.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    VendingRebateForm,
    SalesRebateForm,
    ReferringRebateForm,
    VendingRebateDatatable,
    SalesRebateDatatable,
    ReferringRebateDatatable,
    YonaEditDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const currentTabIdx = ref(0)
    const isFormOpen = ref(false)
    const isValidToSubmit = ref(false)
    const isSubmitting = ref(false)
    const vendingRebates = ref([])
    const salesRebates = ref([])
    const referringRebates = ref([])
    const vendibles = ref([])
    const vendibleTags = ref([])
    const referrals = ref([])
    const tableFilters = ref([])
    const currentForm = ref({ type: 'VendingRebate' })
    const types = [
      { value: 'VendingRebate', text: '商品' },
      { value: 'SalesRebate', text: '売上' },
      { value: 'ReferringRebate', text: '紹介' },
    ]
    const apis = {
      VendingRebate: VendingRebateApi,
      SalesRebate: SalesRebateApi,
      ReferringRebate: ReferringRebateApi,
    }
    const { isLoading, initWith } = useCompInit()

    const rebatesByType = computed(() => {
      return {
        VendingRebate: vendingRebates.value,
        SalesRebate: salesRebates.value,
        ReferringRebate: referringRebates.value,
      }
    })

    const cancelForm = () => {
      isFormOpen.value = false
      currentForm.value = { type: types[currentTabIdx.value].value }
    }

    const submit = async () => {
      if (!isValidToSubmit.value) return

      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const { type } = currentForm.value

      const res = await apis[type][`${fnVerb}${type}`](currentForm.value)

      if (res?.data) {
        const rebate = res.data[camelCase(type)]?.data
        const rebates = rebatesByType.value[type]

        if (isUpdate) {
          const rebateIdx = findIndex(rebates, o => +o.id === +rebate.id)
          rebates.splice(rebateIdx, 1, rebate)
        } else {
          rebates.push(rebate)
        }

        vm.$toast.success(isUpdate ? 'バック要素を更新しました' : 'バック要素を追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async (id, type) => {
      const res = await apis[type][`delete${type}`](id).catch(err => {
        vm.$toast.error(err.message)
      })

      if (res) {
        if (res.data.status === 'error') {
          vm.$toast.error('削除できません')
          vm.$toast.error(res.data.message.join('\n'))

          return
        }

        const rebates = rebatesByType.value[type]
        const rebateIdx = findIndex(rebates, o => +o.id === +id)
        rebates.splice(rebateIdx, 1)
        vm.$toast.success('バック要素を削除しました')
      }
    }

    const getRebates = async () => {
      const res = await RebateApi.getRebates()

      if (res?.status === 200) {
        vendingRebates.value = [...res.data.vendingRebates.data]
        salesRebates.value = [...res.data.salesRebates.data]
        referringRebates.value = [...res.data.referringRebates.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.status === 200) {
        vendibles.value = [...res.data.vendibles.data]
      }
    }

    const getVendibleTags = async () => {
      const res = await VendibleTagApi.getVendibleTags()

      if (res?.status === 200) {
        vendibleTags.value = [...res.data.vendibleTags.data]
      }
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.status === 200) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    const getTableFilters = async () => {
      const res = await TableFilterApi.getTableFilters()

      if (res?.status === 200) {
        tableFilters.value = [...res.data.tableFilters.data]
      }
    }

    initWith([
      getRebates(),
      getVendibles(),
      getReferrals(),
      getVendibleTags(),
      getTableFilters(),
    ])

    return {
      // data
      currentTabIdx,
      isLoading,
      isFormOpen,
      isValidToSubmit,
      isSubmitting,
      vendingRebates,
      salesRebates,
      referringRebates,
      vendibles,
      vendibleTags,
      referrals,
      tableFilters,
      types,
      currentForm,

      // computed
      rebatesByType,

      // method
      cancelForm,
      submit,
      destroy,

      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
